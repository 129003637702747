import { defineStore } from 'pinia'
import { useServiceStore } from './useServicesStore'
import { useSessionStorage, useTimeoutPoll } from '@vueuse/core'
import { useApi } from '@/composable/useApi'
import {
  type ApiDefinition,
  type ServiceStatus,
  type ServiceCheckResults,
  SERVICE_NOT_OK,
  SERVICE_HIGH_LATENCY,
  SERVICE_OK,
  type ServiceCheckResult
} from '@/models/models'
import { computed, ref } from 'vue'
import { getServiceStateFromWorker } from '@/api/serviceCaller'

export const useServiceStateStore = defineStore('serviceStateStore', () => {
  const serviceStore = useServiceStore()

  const SESSION_STORE_STATUS_KEY = 'service-availability'

  const api = useApi()
  const servicesAvailability = useSessionStorage<ServiceCheckResults>(SESSION_STORE_STATUS_KEY, {})
  const loading = ref(false)
  const lastUpdate = ref(Date.now())

  // expose the global status dictionary as a computed property
  const status = computed(() => {
    return servicesAvailability.value
  })

  async function updateServiceState(service: ApiDefinition): Promise<void> {
    servicesAvailability.value[service.name] = await getServiceStateFromWorker(api, service)
  }

  // cycle through all services and update their state
  async function updateAllServices(): Promise<void> {
    loading.value = true
    await Promise.all(serviceStore.services.map((service) => updateServiceState(service)))
    loading.value = false
    lastUpdate.value = Date.now()
  }

  const { isActive, pause, resume } = useTimeoutPoll(
    updateAllServices,
    import.meta.env.VITE_API_POLL_INTERVAL_MS
  )

  const globalStatus = computed((): ServiceStatus => {
    const servicesWithStatus = serviceStore.services
      .map((service) => servicesAvailability.value[service.name])
      .filter((state) => state !== undefined)

    return getCalculatedStatus(servicesWithStatus)
  })

  // calculate the status of a group of services
  function getGroupStatus(groupName: string): ServiceStatus {
    const servicesWithStatus = serviceStore
      .getServicesByGroup(groupName)
      .map((service) => servicesAvailability.value[service.name])
      .filter((state) => state !== undefined)

    return getCalculatedStatus(servicesWithStatus)
  }

  function getCalculatedStatus(servicesWithStatus: ServiceCheckResult[]) {
    // if any service is not ok, return notOk
    if (servicesWithStatus.some((state) => state.status == SERVICE_NOT_OK)) {
      return SERVICE_NOT_OK
    }

    // if any service has a latency higher than the threshold, return HighLatency
    if (servicesWithStatus.some((state) => state.status == SERVICE_HIGH_LATENCY)) {
      return SERVICE_HIGH_LATENCY
    }

    return SERVICE_OK
  }

  return {
    isActive,
    pause,
    resume,
    globalStatus,
    status,
    getGroupStatus,
    loading,
    lastUpdate
  }
})
