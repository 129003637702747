import { UserManager, WebStorageStateStore, User, type UserManagerSettings } from 'oidc-client-ts'

export default class AuthService {
  private userManager: UserManager

  constructor() {
    const settings: UserManagerSettings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: import.meta.env.VITE_OPENID_AUTHORITY_DOMAIN,
      client_id: import.meta.env.VITE_OPENID_CLIENT_ID,
      redirect_uri: import.meta.env.VITE_OPENID_REDIRECT_URI,
      automaticSilentRenew: false,
      response_type: 'code',
      scope: 'openid email profile',
      post_logout_redirect_uri: import.meta.env.VITE_OPENID_POST_LOGOUT_REDIRECT_URI,
      filterProtocolClaims: true,
      response_mode: 'query'
    }

    this.userManager = new UserManager(settings)
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser()
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect()
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect()
  }

  public async getAccessToken(): Promise<string | undefined> {
    const data = await this.userManager.getUser()
    return data?.access_token
  }

  public async isLoggedIn(): Promise<boolean> {
    const user = await this.userManager.getUser()
    return user != null && !user.expired
  }

  public async completeLogin(): Promise<void> {
    await this.userManager.signinCallback()
  }
}
