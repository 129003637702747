<script setup lang="ts">
import ServiceStatus from '@/components/ServiceStatus.vue'
import {
  SERVICE_HIGH_LATENCY_ICON,
  SERVICE_NOT_OK,
  SERVICE_NOT_OK_ICON,
  SERVICE_OK,
  SERVICE_OK_ICON,
  SERVICE_HIGH_LATENCY
} from '@/models/models'
import { useServiceStateStore } from '@/stores/useServiceStateStore'
import { useServiceStore } from '@/stores/useServicesStore'

const serviceStore = useServiceStore()
const serviceStateStore = useServiceStateStore()

const groupIcon = function (group: string) {
  const groupStatus = serviceStateStore.getGroupStatus(group)
  switch (groupStatus) {
    case 'Ok':
      return SERVICE_OK_ICON
    case 'HighLatency':
      return SERVICE_HIGH_LATENCY_ICON
    case 'NotOk':
      return SERVICE_NOT_OK_ICON
    default:
      return 'mdi-help'
  }
}

const groupColor = function (group: string) {
  const groupStatus = serviceStateStore.getGroupStatus(group)
  switch (groupStatus) {
    case SERVICE_OK:
      return 'success'
    case SERVICE_HIGH_LATENCY:
      return 'warning'
    case SERVICE_NOT_OK:
      return 'error'
    default:
      return 'grey'
  }
}
</script>

<template>
  <v-container fluid>
    <v-expansion-panels>
      <v-expansion-panel v-for="group in serviceStore.getGroups()" :key="group">
        <v-expansion-panel-title>
          {{ group }}
          <template v-slot:actions>
            <v-icon :color="groupColor(group)" :icon="groupIcon(group)"></v-icon>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-virtual-scroll :items="serviceStore.getServicesByGroup(group)">
            <template v-slot:default="{ item }">
              <ServiceStatus :name="item.name" :path="item.path" />
            </template>
          </v-virtual-scroll>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
