<script setup lang="ts">
import { RouterView } from 'vue-router'
import AuthService from './services/auth.service'
import { computedAsync } from '@vueuse/core'
import AppToolbar from '@/components/AppToolbar.vue'

const userIsLoggedIn = computedAsync(async () => {
  const authService = new AuthService()
  return await authService.isLoggedIn()
})
</script>

<template>
  <v-app class="rounded rounded-md">
    <v-app-bar>
      <template v-slot:title>
        <v-app-bar-title> Forto Test Page </v-app-bar-title>
      </template>
      <template v-slot:append>
        <AppToolbar></AppToolbar>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-if="userIsLoggedIn">
      <v-list>
        <v-list-item :to="{ name: 'home' }" prepend-icon="mdi-home" title="Home"></v-list-item>
        <v-list-item
          :to="{ name: 'services' }"
          prepend-icon="mdi-code-braces"
          title="Services"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="w-auto">
      <RouterView />
    </v-main>
  </v-app>
</template>
