<script setup lang="ts">
import { useServiceStateStore } from '@/stores/useServiceStateStore'
import AuthService from '@/services/auth.service'
import { computedAsync } from '@vueuse/core'
import { watch } from 'vue'
import dayjs from 'dayjs/esm'

const serviceStateStore = useServiceStateStore()

var user = computedAsync(async () => {
  const authService = new AuthService()
  return await authService.getUser()
}, null)

const userIsLoggedIn = computedAsync(async () => {
  const authService = new AuthService()
  return await authService.isLoggedIn()
})

function login() {
  const authService = new AuthService()
  authService.login()
}

function logout() {
  const authService = new AuthService()
  authService.logout()
}

watch(userIsLoggedIn, (value) => {
  if (value && serviceStateStore.isActive == false) {
    serviceStateStore.resume()
  } else if (!value) {
    serviceStateStore.pause()
  }
})
</script>

<template>
  <v-progress-circular
    v-if="serviceStateStore.loading"
    indeterminate
    size="22"
  ></v-progress-circular>
  <v-chip v-if="userIsLoggedIn" class="ma-2" label>
    Last check: {{ dayjs(serviceStateStore.lastUpdate).format('LTS') }}
  </v-chip>

  <v-tooltip v-if="userIsLoggedIn" location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props">
        <v-icon icon="mdi-account-check"></v-icon>
      </v-btn>
    </template>
    <span>You're logged in as {{ user?.profile.name }}</span>
  </v-tooltip>

  <v-tooltip v-if="!userIsLoggedIn" location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props" @click="login">
        <v-icon icon="mdi-login-variant"></v-icon>
      </v-btn>
    </template>
    <span>Click here to login</span>
  </v-tooltip>

  <v-tooltip v-if="userIsLoggedIn" location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props" @click="logout">
        <v-icon icon="mdi-logout-variant"></v-icon>
      </v-btn>
    </template>
    <span>Click here to logout</span>
  </v-tooltip>
</template>
