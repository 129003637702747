import type { ApiDefinition, WorkerResponse, ServiceCheckResult } from '@/models/models'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'

export async function getServiceStateFromWorker(
  api: AxiosInstance,
  service: ApiDefinition
): Promise<ServiceCheckResult> {
  const start = performance.now()

  const requestConfig: AxiosRequestConfig = {
    signal: AbortSignal.timeout(import.meta.env.VITE_API_TIMEOUT_MS)
  }

  // useful to test with other services that are not Forto, like httpbin
  if (service.customHost) {
    requestConfig.headers = {
      'X-Forto-Host': service.customHost
    }
  } else {
    if (!service.knownHost || service.knownHost == 'tms')
      requestConfig.headers = {
        'X-Forto-Host': import.meta.env.VITE_API_KNOWN_HOST_TMS
      }
    else throw new Error('Unknown host type. This should not happen.')
  }

  try {
  
    const response = await api.get<WorkerResponse>(service.path, requestConfig)

    const performanceResult = performance.now() - start
    return {
      name: service.name,
      lastUpdated: Date.now(),
      workerResponse: response.data,
      roundtripInMs: performanceResult,
      status:
        response.data.status === 'Ok'
          ? performanceResult > import.meta.env.VITE_API_LATENCY_THRESHOLD_MS
            ? 'HighLatency'
            : 'Ok'
          : 'NotOk',
      host: requestConfig.headers['X-Forto-Host']?.toString() ?? ''
    }
  } catch (error: any) {
    if (axios.isCancel(error)) {
      return {
        name: service.name,
        lastUpdated: Date.now(),
        workerResponse: {
          status: 'Timeout',
          body: 'The request timed out',
          statusCode: 408,
          roundtripInMs: import.meta.env.VITE_API_TIMEOUT_MS
        },
        roundtripInMs: import.meta.env.VITE_API_TIMEOUT_MS,
        status: 'NotOk',
        host: requestConfig.headers['X-Forto-Host']?.toString() ?? ''
      }
    }

    console.error('Error updating service state. This should not happen.', error)

    return {
      name: service.name,
      lastUpdated: Date.now(),
      workerResponse: {
        status: 'Failure',
        body: 'Error updating service state',
        statusCode: 500,
        roundtripInMs: 0
      },
      roundtripInMs: 0,
      status: 'NotOk',
      host: requestConfig.headers['X-Forto-Host']?.toString() ?? ''
    }
  }
}
