import axios, { type AxiosInstance } from 'axios'
import AuthService from '../services/auth.service'

let api: AxiosInstance

export function createApi() {
  // Here we set the base URL for all requests made to the api
  api = axios.create({
    baseURL: ''
  })

  // We set an interceptor for each request to
  // include Bearer token to the request if user is logged in
  api.interceptors.request.use(async (config: any) => {
    const authService = new AuthService()
    try {
      const token = await authService.getAccessToken()

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    } catch (e) {
      console.error('Error getting token', e)
    }

    config.headers.set('X-Forto-Api-Path', true)

    return config
  })

  return api
}

export function useApi() {
  if (!api) {
    createApi()
  }
  return api
}
