import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ServicesView from '../views/ServicesView.vue'
import AuthCallback from '../views/AuthCallback.vue'
import AuthService from '@/services/auth.service'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/services',
      name: 'services',
      component: ServicesView
    },
    {
      path: '/auth-callback',
      name: 'callback',
      component: AuthCallback
    }
  ]
})

router.beforeEach(async (to) => {
  if (to.name === 'callback') return true
  if (to.name === 'home') return true

  const authService = new AuthService()
  const isLoggedIn = await authService.isLoggedIn()
  if (!isLoggedIn) return '/'
})

export default router
