<script setup lang="ts">
import AuthService from '@/services/auth.service'
import { onMounted, ref } from 'vue'

const errorHappened = ref<boolean>(false)

onMounted(async () => {
  try {
    const authService = new AuthService()
    await authService.completeLogin()
    window.location.href = '/'
  } catch (error) {
    errorHappened.value = true
    console.error(error)
  }
})
</script>
<template>
  <v-container fluid>
    <v-card
      v-if="!errorHappened"
      title="Authorizing"
      text="Completing login..."
      class="mb-5"
      variant="tonal"
      loading="true"
    ></v-card>
    <v-alert
      v-if="errorHappened"
      title="Authentication failed."
      text="There was an error while trying to complete the login. Please try again."
      type="error"
    ></v-alert>
  </v-container>
</template>
