import { defineStore } from 'pinia'
import {
  SESSION_STORE_SERVICES_KEY,
  type ApiDefinition,
  type ApiGroupDefinition
} from '@/models/models'
import { useStorage } from '@vueuse/core'
import { computed } from 'vue'

export const useServiceStore = defineStore('serviceStore', () => {
  // load all services from services.local.json file in public folder
  const emptyServiceGroups: ApiGroupDefinition[] = []
  const serviceGroups = useStorage(SESSION_STORE_SERVICES_KEY, emptyServiceGroups, sessionStorage)

  // get all groups sorted by name
  function getGroups(): string[] {
    return serviceGroups.value.map((serviceGroup) => serviceGroup.name).sort()
  }

  // get all services by group
  function getServicesByGroup(group: string): ApiDefinition[] {
    return (
      serviceGroups.value
        .find((serviceGroup) => serviceGroup.name === group)
        ?.services.sort((a, b) => a.name.localeCompare(b.name)) || []
    )
  }

  const services = computed(() => {
    return serviceGroups.value.flatMap((serviceGroup) => serviceGroup.services)
  })

  return { services, serviceGroups, getGroups, getServicesByGroup }
})
