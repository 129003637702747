<script setup lang="ts">
import {
  SERVICE_HIGH_LATENCY_ICON,
  SERVICE_NOT_OK_ICON,
  SERVICE_OK_ICON,
  SERVICE_OK,
  SERVICE_NOT_OK,
  SERVICE_HIGH_LATENCY
} from '@/models/models'
import AuthService from '@/services/auth.service'
import { useServiceStateStore } from '@/stores/useServiceStateStore'
import { computedAsync } from '@vueuse/core'

const serviceStateStore = useServiceStateStore()

const userIsLoggedIn = computedAsync(async () => {
  const authService = new AuthService()
  return await authService.isLoggedIn()
})
</script>

<template>
  <v-container fluid>
    <v-card title="Home" text="Welcome to Forto Test Page." class="mb-5" variant="tonal"></v-card>
    <div v-if="userIsLoggedIn">
      <v-alert
        v-if="serviceStateStore.globalStatus == SERVICE_OK"
        title="Everything is awesome."
        text="It looks like all services are online and responsive."
        type="success"
        :icon="SERVICE_OK_ICON"
      ></v-alert>
      <v-alert
        v-else-if="serviceStateStore.globalStatus == SERVICE_NOT_OK"
        title="Houston, we have a problem."
        text="It looks like some services are offline or unresponsive. Check for details in the Services page."
        type="error"
        :icon="SERVICE_NOT_OK_ICON"
      ></v-alert>
      <v-alert
        v-else-if="serviceStateStore.globalStatus == SERVICE_HIGH_LATENCY"
        title="Stuff could be better."
        text="It looks like all services are responsive, but latency is higher than expected. Check for details in the Services page."
        type="warning"
        :icon="SERVICE_HIGH_LATENCY_ICON"
      ></v-alert>
    </div>
  </v-container>
</template>
