import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import dayjs from 'dayjs/esm'
import localizedFormat from 'dayjs/esm/plugin/localizedFormat'
import duration from 'dayjs/esm/plugin/duration'
import relativeTime from 'dayjs/esm/plugin/relativeTime'

dayjs.extend(localizedFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import AsyncComputed from 'vue-async-computed'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { SESSION_STORE_SERVICES_KEY } from './models/models'

const vuetify = createVuetify({
  components,
  directives
})

fetch(`/services.${import.meta.env.MODE}.json`)
  .then((response) => response.text())
  .then((data) => {
    sessionStorage.setItem(SESSION_STORE_SERVICES_KEY, data)
  })
  .then(() => {
    loadApp()
  })

function loadApp() {
  const app = createApp(App)

  app.use(createPinia())
  app.use(AsyncComputed)
  app.use(router)
  app.use(vuetify)

  app.mount('#app')
}
