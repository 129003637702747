export const SESSION_STORE_SERVICES_KEY = 'service-groups'

export interface ApiGroupDefinition {
  name: string
  services: ApiDefinition[]
}

export type KnownHostType = 'tms'
export interface ApiDefinition {
  path: string
  name: string
  knownHost?: KnownHostType
  customHost?: string
}

export const SERVICE_OK = 'Ok'
export const SERVICE_NOT_OK = 'NotOk'
export const SERVICE_HIGH_LATENCY = 'HighLatency'

export type ServiceStatus = typeof SERVICE_OK | typeof SERVICE_NOT_OK | typeof SERVICE_HIGH_LATENCY
export type WorkerResponseStatus = 'Ok' | 'NotOk' | 'Timeout' | 'Failure'

export interface WorkerResponse {
  status: WorkerResponseStatus
  body: string
  statusCode: number
  roundtripInMs: number
}

export interface ServiceCheckResult {
  name: string
  lastUpdated: number
  workerResponse: WorkerResponse
  roundtripInMs: number
  status: ServiceStatus
  host: string
}

export type ServiceCheckResults = Record<string, ServiceCheckResult>

export const SERVICE_OK_ICON = 'mdi-checkbox-marked-circle-outline'
export const SERVICE_NOT_OK_ICON = 'mdi-alert-circle-outline'
export const SERVICE_HIGH_LATENCY_ICON = 'mdi-timer-sand'
