<script setup lang="ts">
import {
  SERVICE_OK,
  type ServiceCheckResult,
  SERVICE_HIGH_LATENCY,
  SERVICE_NOT_OK
} from '@/models/models'
import { useServiceStateStore } from '@/stores/useServiceStateStore'
import { computed } from 'vue'
import dayjs from 'dayjs/esm'

const props = defineProps<{
  name: string,
  path: string
}>()

const serviceStateStore = useServiceStateStore()

const serviceStatus = computed((): ServiceCheckResult | undefined => {
  if (!props.name) return undefined
  return serviceStateStore.status[props.name]
})

const isOnline = computed(() => {
  return serviceStatus.value?.status == SERVICE_OK
})

const isOnlineHighLatency = computed(() => {
  return serviceStatus.value?.status == SERVICE_HIGH_LATENCY
})

const isOffline = computed(() => {
  return serviceStatus.value?.status == SERVICE_NOT_OK
})

const loading = computed(() => {
  return serviceStatus.value == undefined
})

const roundtripInMsServerToServer = computed(() => {
  return serviceStatus.value?.workerResponse.roundtripInMs == undefined
    ? 'N/A'
    : serviceStatus.value?.workerResponse.roundtripInMs
})

const roundtripInMsComputerToServer = computed(() => {
  return serviceStatus.value?.roundtripInMs == undefined
    ? 'N/A'
    : Math.round(serviceStatus.value?.roundtripInMs)
})
</script>

<template>
  <v-col>
    <v-card :loading="loading">
      <v-card-title>{{ name }}</v-card-title>
      <v-card-subtitle>{{ path }}</v-card-subtitle>
      <v-card-text>
        <div class="d-flex justify-flex-start flex-wrap align-center ga-2">
          <v-chip v-if="isOnline" color="success" label>Online</v-chip>
          <v-chip v-if="isOnlineHighLatency" color="warning" label>High Latency</v-chip>
          <v-chip v-if="isOffline" color="error" label>Offline</v-chip>
          <v-chip v-if="isOffline" color="error" label>{{
            serviceStatus?.workerResponse.status
          }}</v-chip>

          <v-chip v-if="serviceStatus" color="info" label
            >{{ serviceStatus.host }}</v-chip
          >
          <v-chip v-if="isOnline || isOnlineHighLatency" color="info" label
            >Server to server: {{ roundtripInMsServerToServer }} ms</v-chip
          >
          <v-chip v-if="isOnline || isOnlineHighLatency" color="info" label
            >Computer to server: {{ roundtripInMsComputerToServer }} ms</v-chip
          >
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>
